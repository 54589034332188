<template>
    <b-card class="mb-0" :title="$t('card.title')">
        <b-card-text v-if="showEmpty" class="text-center text-muted">
            {{ $t('card.empty') }}
        </b-card-text>

        <div
            v-for="(card, id) in sortedPaymentCards"
            v-else
            :key="id"
            class="mt-1 p-1 rounded bg-lighten-5"
            :class="getCardVariant(card).background"
        >
            <div class="d-flex align-items-center justify-content-between">
                <bank-card-mask
                    :type="card.type"
                    :mask="card.mask"
                    :is-bold="true"
                />

                <h6 class="m-0">
                    {{ getFormattedBankCardDate(card.exp_date) }}
                </h6>
            </div>

            <div class="mt-50">
                {{ (card.issuer || '-') + ', ' + card.country }}
            </div>

            <div v-if="card.block" cols="12" class="mt-50">
                <span>
                    {{ getCardVariant(card).text.block }}
                </span>

                <span>
                    {{ getBlockDate(card) }}
                </span>
            </div>

            <div cols="12" class="mt-50 d-flex justify-content-end">
                <b-badge :variant="getCardVariant(card).color">
                    {{ getCardVariant(card).text.status }}

                    <feather-icon
                        :icon="getCardVariant(card).icon"
                        size="18"
                        class="ml-50"
                    />
                </b-badge>
            </div>
        </div>
    </b-card>
</template>

<script>
import { BCard, BCardText, BBadge } from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
import BankCardMask from '@/views/components/bankcard/BankCardMask.vue';
import useUserBankCards from './useUserBankCards';

export default {
    components: {
        BCard,
        BCardText,
        BBadge,
        BankCardMask,
    },

    directives: {
        Ripple,
    },

    props: {
        paymentCards: {
            type: Array,
            required: true,
        },
    },

    computed: {
        showEmpty() {
            return (
                Array.isArray(this.paymentCards) && !this.paymentCards.length
            );
        },

        sortedPaymentCards() {
            const tempPaymentCards = [...this.paymentCards];

            return tempPaymentCards.sort((card) => (card.is_default ? -1 : 1));
        },
    },

    setup() {
        const {
            getCardVariant,
            getBlockDate,
            getFormattedBankCardDate,
        } = useUserBankCards();

        return {
            // Methods
            getCardVariant,
            getBlockDate,

            // External methods
            getFormattedBankCardDate,
        };
    },
};
</script>

<style lang="scss" scoped></style>
