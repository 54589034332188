export const getFromLocalStorage = (key) => {
    if (!key) return null;

    const value = localStorage.getItem(key);

    try {
        return JSON.parse(value);
    } catch (error) {
        return value;
    }
};

export const setToLocalStorage = (key, value) => {
    if (!key || !value) return;

    let valueToSet = value;

    if (typeof value === 'object' && value !== null) {
        valueToSet = JSON.stringify(value);
    }

    localStorage.setItem(key, valueToSet);
};
