import { ref } from '@vue/composition-api';

export default function useModalsConfirm({ root }, modalID = 'modalID') {
    const selectedID = ref(null);

    const resetSelectedID = () => {
        selectedID.value = null;
    };

    const openConfirmModal = (id = null) => {
        selectedID.value = id;

        root.$bvModal.show(modalID);
    };

    return {
        // Reactive data
        selectedID,
        root,

        // Methods
        resetSelectedID,
        openConfirmModal,
    };
}
