<template>
    <b-card>
        <b-row>
            <!-- User Info: Left col -->
            <b-col
                cols="21"
                xl="7"
                class="d-flex justify-content-between flex-column"
            >
                <!-- User Avatar & Action Buttons -->
                <div class="d-flex justify-content-start">
                    <b-avatar
                        :src="userData.avatar"
                        :text="avatarText(userData.email)"
                        :variant="`light-${theme}`"
                        size="104px"
                        rounded
                    />

                    <div class="d-flex flex-column ml-1">
                        <!-- Email & Username -->
                        <div>
                            <h4 class="mb-0">
                                {{ loginFromEmail }}
                            </h4>

                            <span class="card-text">{{ userData.email }}</span>
                        </div>

                        <!-- Buttons -->
                        <div class="d-flex flex-wrap">
                            <b-button
                                :to="{
                                    name: 'users-edit',
                                    params: { id: userID },
                                }"
                                variant="primary"
                                class="mt-1 mr-1"
                            >
                                {{ $t('user.edit') }}
                            </b-button>

                            <b-button
                                variant="outline-danger"
                                class="mt-1 mr-1"
                            >
                                {{ $t('user.block') }}
                            </b-button>

                            <b-button
                                v-clipboard:copy="userData.email"
                                v-clipboard:success="onCopySuccess"
                                v-clipboard:error="onCopySuccessError"
                                variant="outline-secondary"
                                class="mt-1"
                            >
                                <feather-icon
                                    icon="MailIcon"
                                    size="16"
                                    class="align-middle text-body"
                                />
                            </b-button>
                        </div>
                    </div>
                </div>

                <!-- User Stats -->
                <div class="d-flex align-items-center mt-2">
                    <div class="d-flex align-items-center mr-2">
                        <b-avatar variant="light-primary" rounded>
                            <feather-icon icon="DollarSignIcon" size="18" />
                        </b-avatar>

                        <div class="ml-1">
                            <h5 class="mb-0">0&#8381;</h5>

                            <small>
                                {{ $t('user.income') }}
                            </small>
                        </div>
                    </div>

                    <div class="d-flex align-items-center">
                        <b-avatar variant="light-success" rounded>
                            <feather-icon icon="TrendingUpIcon" size="18" />
                        </b-avatar>

                        <div class="ml-1">
                            <h5 class="mb-0">1,2 {{ $t('ui.hours') }}</h5>

                            <small>
                                {{ $t('user.inGame') }}
                            </small>
                        </div>
                    </div>
                </div>
            </b-col>

            <!-- Right Col: Table -->
            <b-col cols="12" xl="5">
                <table class="mt-2 mt-xl-0 w-100">
                    <tr v-if="userData.username">
                        <th class="pb-50">
                            <feather-icon icon="UserIcon" class="mr-75" />

                            <span class="font-weight-bold">
                                {{ $t('ui.username') }}
                            </span>
                        </th>

                        <td class="pb-50">
                            {{ userData.username }}
                        </td>
                    </tr>

                    <!-- Role row -->
                    <tr>
                        <th class="pb-50">
                            <feather-icon icon="StarIcon" class="mr-75" />

                            <span class="font-weight-bold">
                                {{ $t('user.role') }}
                            </span>
                        </th>

                        <td class="pb-50 text-capitalize">
                            {{ roleName }}
                        </td>
                    </tr>

                    <!-- Phone row -->
                    <tr v-if="userData.phone">
                        <th class="pb-50">
                            <feather-icon icon="PhoneIcon" class="mr-75" />

                            <span class="font-weight-bold">
                                {{ $t('user.phone') }}
                            </span>
                        </th>

                        <td class="pb-50 text-capitalize">
                            {{ userData.phone }}
                        </td>
                    </tr>

                    <!-- User's status row -->
                    <tr>
                        <th class="pb-50">
                            <feather-icon icon="CheckIcon" class="mr-75" />

                            <span class="font-weight-bold">
                                {{ $t('user.status') }}
                            </span>
                        </th>

                        <td class="pb-50 text-capitalize">
                            {{ statusName }}
                        </td>
                    </tr>

                    <!-- Date of registration row -->
                    <tr>
                        <th class="pb-50">
                            <feather-icon icon="CalendarIcon" class="mr-75" />

                            <span class="font-weight-bold">
                                {{ $t('user.registeredAt') }}
                            </span>
                        </th>

                        <td class="pb-50 text-capitalize">
                            {{
                                formatDate(userData.created_at, $i18n.locale, {
                                    month: 'long',
                                    day: 'numeric',
                                    year: 'numeric',
                                })
                            }}
                        </td>
                    </tr>

                    <!-- Subscription row -->
                    <tr>
                        <th class="pb-50">
                            <feather-icon icon="FlagIcon" class="mr-75" />

                            <span class="font-weight-bold">
                                {{ $t('user.subscription') }}
                            </span>
                        </th>

                        <td class="pb-50 text-capitalize">
                            {{ subscriptionText }}
                        </td>
                    </tr>

                    <!-- Auto renewal row -->
                    <tr
                        v-b-tooltip.hover.v-primary.bottom="
                            autoRenewalCanceledAtText
                        "
                        :class="{ 'cursor-pointer': hasAutoRenewal }"
                        @click="openConfirmModal"
                    >
                        <th class="pb-50">
                            <feather-icon icon="ToggleLeftIcon" class="mr-75" />

                            <span class="font-weight-bold">
                                {{ $t('user.autoRenewal') }}
                            </span>
                        </th>

                        <td class="pb-50 text-capitalize">
                            <b-badge
                                pill
                                :variant="`light-${autoRenewalVariant}`"
                                class="text-capitalize"
                            >
                                {{ autoRenewalText }}
                            </b-badge>
                        </td>
                    </tr>
                </table>
            </b-col>
        </b-row>

        <modals-confirm-action
            :modal-i-d="modalCancelAutoRenewalID"
            @submit="cancelAutoRenewal"
        >
            <template #title>
                {{ $t('user.disableAutorenewal') }}
            </template>

            <template #message>
                {{ $t('ui.areYouSure') }}
            </template>
        </modals-confirm-action>
    </b-card>
</template>

<script>
import {
    BCard,
    BButton,
    BAvatar,
    BRow,
    BCol,
    BBadge,
    VBModal,
    VBTooltip,
} from 'bootstrap-vue';
import {
    subscriptionBadgeText,
    formatDate,
    avatarText,
} from '@/utils/formatter';
import ModalsConfirmAction from '@/views/components/modals/ModalsConfirmAction.vue';
import usePositionedToast from '@/utils/usePositionedToast';
import useUsers from '../useUsers';

export default {
    components: {
        BCard,
        BButton,
        BRow,
        BCol,
        BAvatar,
        BBadge,

        ModalsConfirmAction,
    },

    directives: {
        'b-modal': VBModal,
        'b-tooltip': VBTooltip,
    },

    props: {
        userData: {
            type: Object,
            required: true,
        },

        userID: {
            type: Number,
            required: true,
        },
    },

    data() {
        return {
            modalCancelAutoRenewalID: 'modalCancelAutoRenewal',
        };
    },

    computed: {
        autoRenewalCanceledAtText() {
            const { canceled_at: canceledAt } =
                this.userData?.subscription?.cancel || {};

            if (!canceledAt) return null;

            return `${this.$t('user.autoRenewalCancelledAt')} ${formatDate(
                canceledAt,
                this.$i18n.locale,
                {
                    month: 'long',
                    day: 'numeric',
                    year: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit',
                    second: '2-digit',
                }
            )}`;
        },

        statusName() {
            const { status: { name = '' } = {} } = this.userData || {};

            return name;
        },

        roleName() {
            const { role: { name = '' } = {} } = this.userData || {};

            return name;
        },

        loginFromEmail() {
            const { email = '' } = this.userData || {};

            return email.split('@')[0];
        },

        theme() {
            const { role: { theme = 'secondary' } = {} } = this.userData || {};

            return theme;
        },

        subscriptionText() {
            const { subscription } = this.userData;

            if (!subscription || !subscription.is_active)
                return this.$t('user.none');

            if (subscription.is_active && subscription.expired_at === null) {
                return this.$t('user.lifetime');
            }

            return `${this.$t('user.until')} ${this.formatDate(
                subscription.expired_at,
                this.$i18n.locale,
                {
                    month: 'long',
                    day: 'numeric',
                    year: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit',
                }
            )}`;
        },

        hasAutoRenewal() {
            return !!this.userData?.subscription?.auto_replenish;
        },

        autoRenewalText() {
            const { subscription } = this.userData;

            if (!subscription || !subscription.auto_replenish)
                return this.$t('user.autoRenewalCancelled');

            return this.$t('user.autoRenewalActive');
        },

        autoRenewalVariant() {
            const { subscription } = this.userData;

            if (!subscription || !subscription.auto_replenish) return 'danger';

            return 'success';
        },
    },

    methods: {
        openConfirmModal() {
            if (!this.hasAutoRenewal) return;

            this.$bvModal.show(this.modalCancelAutoRenewalID);
        },

        cancelAutoRenewal() {
            this.cancelAutoRenewalByUserID(this.userID).then((response) => {
                this.userData.subscription.auto_replenish = false;

                this.showNotification({ title: response?.data?.message });
            });
        },

        onCopySuccess() {
            this.showNotification({ title: this.$t('toasts.copyToClipboard') });
        },

        onCopySuccessError() {
            this.showNotification({
                variant: 'danger',
                title: this.$t('toasts.fail'),
            });
        },
    },

    setup() {
        const {
            resolveUserRoleVariant,
            resolveUserSubscriptionVariant,
            cancelAutoRenewalByUserID,
        } = useUsers();

        const { showNotification } = usePositionedToast();

        return {
            avatarText,
            formatDate,
            resolveUserRoleVariant,
            resolveUserSubscriptionVariant,
            subscriptionBadgeText,
            cancelAutoRenewalByUserID,

            showNotification,
        };
    },
};
</script>

<style scoped>
.cursor-pointer {
    cursor: pointer;
}
</style>
