<template>
    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
        <div class="m-2">
            <!-- Table Top -->
            <b-row
                class="d-flex flex-column flex-md-row justify-content-between"
            >
                <!-- Left column - Title -->
                <b-col
                    cols="3"
                    class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
                >
                    <h4 class="mb-0">
                        {{ $t('payments.title') }}
                    </h4>
                </b-col>

                <!-- Right column - Search -->
                <b-col cols="12" md="6" xl="5" class="ml-auto">
                    <div class="d-flex align-items-center">
                        <!-- Search -->
                        <b-form-input
                            v-model="searchQuery"
                            class="d-inline-block mr-1"
                            :placeholder="$t('ui.search')"
                        />

                        <!-- Checkbox -->
                        <b-form-checkbox
                            v-model="showOnlySuccessfulPayments"
                            name="check-button"
                            switch
                            inline
                            class="text_one-line"
                        >
                            <span class="cursor_behavior_pointer">
                                {{ $t('payments.successOnly') }}
                            </span>
                        </b-form-checkbox>
                    </div>
                </b-col>
            </b-row>
        </div>

        <b-table
            ref="refUserViewUserPaymensTable"
            class="position-relative"
            :items="paymentsDataPerPage"
            responsive
            :fields="tableColumns"
            primary-key="id"
            show-empty
            :empty-text="$t('ui.searchNotFound')"
            no-local-sorting
            no-footer-sorting
        >
            <!-- Column: Status -->
            <template #cell(status)="data">
                <b-badge
                    v-b-tooltip.hover.v-primary.bottom="data.item.reason"
                    pill
                    :variant="`light-${resolvePaymentStatusVariantByID(
                        data.item.status.id
                    )}`"
                    class="text-capitalize cursor_behavior_pointer"
                >
                    {{ data.item.status.name }}
                </b-badge>
            </template>

            <!-- Column: Card -->
            <template #cell(card)="data">
                <bank-card-mask
                    :type="data.item.card.type"
                    :mask="data.item.card.mask"
                />
            </template>

            <!-- Column: Date -->
            <template #cell(date)="data">
                {{
                    formatDate(data.item.date, $i18n.locale, {
                        month: 'long',
                        day: 'numeric',
                        year: 'numeric',
                        hour: '2-digit',
                        minute: '2-digit',
                        second: '2-digit',
                    })
                }}
            </template>

            <!-- Column: Amount -->
            <template #cell(amount)="data">
                <span class="font-weight-bolder">
                    {{ data.item.amount + ' ' + data.item.currency_symbol }}
                </span>
            </template>

            <!-- Column: Actions -->
            <template #cell(actions)="data">
                <!-- View payment button -->
                <b-button
                    v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                    variant="flat-secondary"
                    class="btn-icon"
                    :to="`/transactions/${data.item.payment_id}/view`"
                >
                    <feather-icon icon="EyeIcon" />
                </b-button>

                <!-- Refund payment button -->
                <b-button
                    v-if="isRefundButtonShouldBeShown(data)"
                    v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                    variant="flat-secondary"
                    class="btn-icon"
                    @click="openConfirmModal(data.item.id)"
                >
                    <feather-icon icon="CornerUpLeftIcon" />
                </b-button>
            </template>
        </b-table>

        <div class="mx-2 mb-2">
            <b-row>
                <b-col
                    cols="12"
                    sm="6"
                    class="d-flex align-items-center justify-content-center justify-content-sm-start"
                >
                    <i18n class="text-muted" tag="span" path="ui.showing">
                        <template v-slot:from>
                            {{ dataMeta.from }}
                        </template>

                        <template v-slot:to>
                            {{ dataMeta.to }}
                        </template>

                        <template v-slot:of>
                            {{ dataMeta.of }}
                        </template>
                    </i18n>
                </b-col>

                <!-- Pagination -->
                <b-col
                    cols="12"
                    sm="6"
                    class="d-flex align-items-center justify-content-center justify-content-sm-end"
                >
                    <b-pagination
                        v-model="currentPage"
                        :total-rows="totalPayments"
                        :per-page="perPage"
                        first-number
                        last-number
                        class="mb-0 mt-1 mt-sm-0"
                        prev-class="prev-item"
                        next-class="next-item"
                    >
                        <template #prev-text>
                            <feather-icon icon="ChevronLeftIcon" size="18" />
                        </template>

                        <template #next-text>
                            <feather-icon icon="ChevronRightIcon" size="18" />
                        </template>
                    </b-pagination>
                </b-col>
            </b-row>
        </div>

        <modals-confirm-action
            :modal-i-d="modalMakeARefundID"
            @cancel="resetSelectedID"
            @submit="makeARefund"
        >
            <template #title>
                {{ $t('payments.makeRefund') }}
            </template>

            <template #message>
                {{ $t('ui.areYouSure') }}
            </template>
        </modals-confirm-action>
    </b-card>
</template>

<script>
import {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BFormCheckbox,
    BTable,
    BPagination,
    BButton,
    BBadge,
    VBTooltip,
} from 'bootstrap-vue';
import ModalsConfirmAction from '@/views/components/modals/ModalsConfirmAction.vue';
import usePositionedToast from '@/utils/usePositionedToast';
import BankCardMask from '@/views/components/bankcard/BankCardMask.vue';
import Ripple from 'vue-ripple-directive';
import { formatDate, resolvePaymentStatusVariantByID } from '@/utils/formatter';
import useModalsConfirm from '@/views/components/modals/useModalsConfirm';
import useUserPayments from './useUserPayments';

export default {
    components: {
        BCard,
        BRow,
        BCol,
        BFormInput,
        BFormCheckbox,
        BTable,
        BPagination,
        BButton,
        BBadge,

        BankCardMask,

        ModalsConfirmAction,
    },

    directives: {
        Ripple,
        'b-tooltip': VBTooltip,
    },

    props: {
        userData: {
            type: Object,
            required: true,
        },
    },

    methods: {
        makeARefund() {
            this.makeARefundByTransactionID(this.selectedID)
                .then((response) => {
                    this.showNotification({ title: response?.data?.message });
                })
                .finally(() => {
                    this.resetSelectedID();
                });
        },

        isRefundButtonShouldBeShown(data) {
            const notRefundedYet = !data.item.refund_transaction_uid;
            const isStatusSuccess = this.successStatusesIDs.includes(
                data.item.status.id
            );

            return isStatusSuccess && notRefundedYet;
        },
    },

    setup(props, context) {
        const modalMakeARefundID = 'modalMakeARefund';
        const {
            selectedID,
            resetSelectedID,
            openConfirmModal,
        } = useModalsConfirm(context, modalMakeARefundID);

        const { showNotification } = usePositionedToast();
        const {
            successStatusesIDs,
            refUserViewUserPaymensTable,
            perPage,
            totalPayments,
            currentPage,
            dataMeta,
            paymentsDataPerPage,
            showOnlySuccessfulPayments,
            tableColumns,
            searchQuery,
            sortBy,
            isSortDirDesc,
            makeARefundByTransactionID,
        } = useUserPayments({ props });

        return {
            // Non reactive data
            successStatusesIDs,

            // Reactive data
            modalMakeARefundID,
            selectedID,
            resetSelectedID,
            openConfirmModal,

            refUserViewUserPaymensTable,
            perPage,
            totalPayments,
            currentPage,
            dataMeta,
            paymentsDataPerPage,
            showOnlySuccessfulPayments,
            tableColumns,
            searchQuery,
            sortBy,
            isSortDirDesc,

            // Methods
            makeARefundByTransactionID,
            showNotification,

            // External methods
            formatDate,
            resolvePaymentStatusVariantByID,
        };
    },
};
</script>

<style lang="scss" scoped>
.cursor_behavior_pointer {
    cursor: pointer;
}

.text_one-line {
    white-space: nowrap;
}
</style>
