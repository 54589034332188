<template>
    <div>
        <!-- Spinner -->
        <div v-if="isLoading" class="centered">
            <b-spinner variant="primary" />
        </div>

        <!-- Alert: No item found -->
        <b-alert variant="danger" :show="isErrorOccured">
            <h4 class="alert-heading">
                {{ $t('user.fetchingError') }}
            </h4>

            <i18n tag="div" class="alert-body" path="user.checkList">
                <template v-slot:action>
                    <b-link class="alert-link" :to="{ name: 'users-list' }">
                        {{ $t('user.list') }}
                    </b-link>
                </template>
            </i18n>
        </b-alert>

        <template v-if="isUserDataFetched">
            <!-- First Row -->
            <b-row class="mb-2">
                <!-- Info about User -->
                <b-col cols="12" xl="9" class="mb-2 mb-xl-0">
                    <user-view-user-info-card
                        class="h-100 mb-0"
                        :user-data="userData"
                        :user-i-d="userID"
                    />
                </b-col>

                <!-- Change Strategy -->
                <b-col cols="12" xl="3" lg="6">
                    <user-view-user-strategy-card
                        class="h-100 mb-0"
                        :user-data="userData"
                    />
                </b-col>
            </b-row>

            <!-- Second Row -->
            <b-row class="mb-2">
                <!-- Payments table -->
                <b-col cols="12" xl="9">
                    <user-view-user-payments-card :user-data="userData" />
                </b-col>

                <b-col cols="12" xl="3">
                    <user-view-bank-cards
                        :payment-cards="userData.bank_cards || []"
                    />
                </b-col>
            </b-row>
        </template>
    </div>
</template>

<script>
import router from '@/router';
import { ref } from '@vue/composition-api';
import { BRow, BCol, BAlert, BLink, BSpinner } from 'bootstrap-vue';
import useUsers from '@/views/users/useUsers';
import UserViewUserInfoCard from './UserViewUserInfoCard.vue';
import UserViewUserStrategyCard from './UserViewUserStrategyCard.vue';
import UserViewUserPaymentsCard from './UserViewUserPaymentsCard.vue';
import UserViewBankCards from './UserViewBankCards.vue';

export default {
    components: {
        BRow,
        BCol,
        BAlert,
        BLink,
        BSpinner,

        // Local Components
        UserViewUserInfoCard,
        UserViewUserStrategyCard,
        UserViewUserPaymentsCard,
        UserViewBankCards,
    },

    computed: {
        isErrorOccured() {
            return this.userData === null && !this.isLoading;
        },

        isUserDataFetched() {
            return this.userData && !this.isLoading;
        },

        userID() {
            const { id = this.$route.params.id } = this.userData || {};

            try {
                return Number.parseInt(id, 10);
            } catch (error) {
                return null;
            }
        },
    },

    setup() {
        const isLoading = ref(true);
        const userData = ref({});
        const { fetchUser } = useUsers();

        fetchUser(router.currentRoute.params.id)
            .then((response) => {
                userData.value = response.data;
            })
            .catch(() => {
                userData.value = null;
            })
            .finally(() => {
                isLoading.value = false;
            });

        return {
            // Reactive data
            isLoading,

            // Data
            userData,

            // Methods
            fetchUser,
        };
    },
};
</script>

<style>
.centered {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
</style>
