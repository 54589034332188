import i18n from '@/libs/i18n/index';
import store from '@/store';
import {
    ref,
    reactive,
    onMounted,
    computed,
    watch,
} from '@vue/composition-api';
import { getTransactionSuccessStatusesIDs } from '@/utils/transactions-statuses';
import { getFromLocalStorage, setToLocalStorage } from '@/utils/localStorage';

// Notification
import useHandleError from '@/utils/useHandleError';

export default function useUserPayments({ props = {} }) {
    const { showErrorMessage } = useHandleError();
    const i18nReactive = reactive(i18n);

    const getLocalizedLabels = () => {
        return [
            {
                key: 'id',
                label: i18nReactive.t('payments.transactionId'),
            },
            {
                key: 'payment_id',
                label: i18nReactive.t('payments.orderId'),
            },
            {
                key: 'status',
                label: i18nReactive.t('payments.status'),
            },
            {
                key: 'card',
                label: i18nReactive.t('payments.card'),
            },
            {
                key: 'date',
                label: i18nReactive.t('payments.date'),
            },
            {
                key: 'amount',
                label: i18nReactive.t('payments.amount'),
            },
            {
                key: 'actions',
                label: i18nReactive.t('ui.actions'),
            },
        ];
    };

    const successOnlySwitchKey = 'successOnlySwitch';

    const getSuccessOnlySwitchValue = () => {
        const { value = true } =
            getFromLocalStorage(successOnlySwitchKey) || {};

        return !!value;
    };

    // Table Handlers
    const refUserViewUserPaymensTable = ref(null);
    const perPage = ref(10);
    const totalPayments = ref(0);
    const currentPage = ref(1);
    const showOnlySuccessfulPayments = ref(getSuccessOnlySwitchValue());
    const tableColumns = ref(getLocalizedLabels());
    const searchQuery = ref('');
    const sortBy = ref('amount');
    const isSortDirDesc = ref(true);
    const paymentsDataAll = ref([]);
    const paymentsDataPerPage = ref([]);

    const refetchTableColumnLables = () => {
        tableColumns.value = getLocalizedLabels();
    };

    const successStatusesIDs = getTransactionSuccessStatusesIDs([
        'completed',
        'authorized',
    ]);

    const getPaymentsDataPerPage = (payments) => {
        const from = (currentPage.value - 1) * perPage.value;
        const to = (currentPage.value - 1) * perPage.value + perPage.value;

        return payments.slice(from, to);
    };

    const getSortedPaymentsByDate = (payments) => {
        return payments.sort((currentPayment, pastPayment) => {
            const { date: currentDate } = currentPayment;
            const { date: pastDate } = pastPayment;

            return new Date(pastDate) - new Date(currentDate);
        });
    };

    const getPaymentsData = () => {
        let { transactions: payments = [] } = props.userData;

        payments = getSortedPaymentsByDate(payments);

        if (showOnlySuccessfulPayments.value) {
            const successPayments = payments.filter((payment) =>
                successStatusesIDs.includes(payment.status.id)
            );

            paymentsDataAll.value = successPayments;
            paymentsDataPerPage.value = getPaymentsDataPerPage(successPayments);
            totalPayments.value = successPayments.length;
            return;
        }

        paymentsDataAll.value = payments;
        paymentsDataPerPage.value = getPaymentsDataPerPage(payments);
        totalPayments.value = payments.length;
    };

    const getSearchResults = () => {
        if (searchQuery.value === '') return;

        const searchResult = paymentsDataAll.value.filter((payment) =>
            payment.payment_id.toString().includes(searchQuery.value)
        );

        paymentsDataPerPage.value = getPaymentsDataPerPage(searchResult);
        totalPayments.value = searchResult.length;
    };

    const dataMeta = computed(() => {
        const localItemsCount = refUserViewUserPaymensTable.value
            ? refUserViewUserPaymensTable.value.localItems.length
            : 0;

        return {
            from:
                perPage.value * (currentPage.value - 1) +
                (localItemsCount ? 1 : 0),
            to: perPage.value * (currentPage.value - 1) + localItemsCount,
            of: totalPayments.value,
        };
    });

    const refetchPaymentsList = () => {
        getPaymentsData();
        getSearchResults();
    };

    const saveSuccessOnlySwitchValue = (value) => {
        setToLocalStorage(successOnlySwitchKey, { value });
    };

    const makeARefundByTransactionID = (transactionID) => {
        return store
            .dispatch('app-users/makeARefundByTransactionID', {
                locale: i18nReactive.locale,
                transaction_uid: transactionID,
            })
            .catch(showErrorMessage);
    };

    const setQueueStartAtNow = ({ userId }) => {
        return store
            .dispatch('app-users/setQueueStartAtNow', {
                userId,
                locale: i18nReactive.locale,
            })
            .catch(showErrorMessage);
    };

    onMounted(getPaymentsData);

    watch([currentPage, perPage, searchQuery], () => {
        refetchPaymentsList();
    });

    watch(showOnlySuccessfulPayments, (switchValue) => {
        saveSuccessOnlySwitchValue(switchValue);
        refetchPaymentsList();
    });

    watch(
        () => i18nReactive.locale,
        () => refetchTableColumnLables()
    );

    return {
        // Non reactive data
        successStatusesIDs,

        // Reactive data
        refUserViewUserPaymensTable,
        perPage,
        totalPayments,
        currentPage,
        dataMeta,
        paymentsDataAll,
        paymentsDataPerPage,
        showOnlySuccessfulPayments,
        tableColumns,
        searchQuery,
        sortBy,
        isSortDirDesc,

        // Methods
        makeARefundByTransactionID,
        setQueueStartAtNow,
    };
}
