<template>
    <b-card no-body class="border-primary">
        <b-card-header
            class="d-flex justify-content-between align-items-center pt-75 pb-25"
        >
            <h5 class="mb-0">
                {{ $t('strategy.current') }}
            </h5>

            <b-badge variant="light-primary">
                {{ strategyName }}
            </b-badge>

            <!-- <small class="text-muted w-100">
                {{
                    $t('strategy.activeFrom') +
                    ' ' +
                    formatDate(userData.queue.start_at, $i18n.locale)
                }}
            </small> -->
        </b-card-header>

        <b-card-body class="h-100 pb-0 d-flex">
            <div
                class="w-100 d-flex flex-column flex-xl-row justify-content-xl-between align-items-center"
            >
                <span class="align-middle mt-1 mr-auto m-xl-0">
                    {{ descriptionOfNextAction }}
                </span>

                <span class="align-middle mt-1 mr-auto m-xl-0">
                    {{ dateOfNextAction }}
                </span>
            </div>
        </b-card-body>

        <b-card-footer>
            <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                v-b-modal.modalChangeStrategy
                variant="primary"
                block
                class="mt-auto"
            >
                {{ $t('strategy.changeStrategy') }}
            </b-button>
            <b-button
                variant="danger"
                block
                class="mt-8"
                @click="setStartAtNow"
            >
                {{ $t('strategy.setStartAtNow') }}
            </b-button>
        </b-card-footer>

        <!-- select 2 demo -->
        <b-modal
            id="modalChangeStrategy"
            centered
            :title="$t('strategy.changeStrategy')"
        >
            <b-form @submit.stop.prevent>
                <b-form-group
                    :label="$t('strategy.choose')"
                    label-for="vue-select"
                >
                    <v-select
                        id="vue-select"
                        v-model="selectedStrategyID"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="strategiesOptions"
                        class="w-100"
                        label="name"
                        required
                        :reduce="(option) => option.id"
                        :searchable="false"
                    />
                </b-form-group>
            </b-form>

            <template #modal-footer="{ cancel }">
                <b-button variant="outline-secondary" @click="cancel()">
                    {{ $t('ui.cancel') }}
                </b-button>

                <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    class="mt-auto"
                    :disabled="isConfirmButtonDisabled"
                    @click="changeUserStrategy"
                >
                    {{ $t('ui.confirm') }}
                </b-button>
            </template>
        </b-modal>
    </b-card>
</template>

<script>
import {
    BCard,
    BCardHeader,
    BCardFooter,
    BCardBody,
    BBadge,
    BButton,
    BModal,
    VBModal,
    BForm,
    BFormGroup,
} from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
import vSelect from 'vue-select';
import { formatDate } from '@/utils/formatter';
import usePositionedToast from '@/utils/usePositionedToast';
import useUserPayments from '@/views/users/users-view/useUserPayments';

export default {
    directives: {
        Ripple,
        'b-modal': VBModal,
    },

    components: {
        BCard,
        BCardHeader,
        BCardFooter,
        BCardBody,
        BBadge,
        BButton,
        BModal,
        BForm,
        BFormGroup,
        vSelect,
    },

    props: {
        userData: {
            type: Object,
            required: true,
        },
    },

    data() {
        return {
            selectedStrategyID: null,
        };
    },

    computed: {
        strategyName() {
            const { queue: { strategy: { name = '-' } = {} } = {} } =
                this.userData || {};

            return name;
        },

        descriptionOfNextAction() {
            const { queue: { description = '-' } = {} } = this.userData || {};

            return description;
        },

        dateOfNextAction() {
            const { queue: { start_at: startAt = null } = {} } =
                this.userData || {};

            if (startAt === null) return '-';

            return this.formatDate(startAt, this.$i18n.locale, {
                month: 'short',
                day: 'numeric',
                year: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
            });
        },

        isConfirmButtonDisabled() {
            const isStrategyNotSelected = this.selectedStrategyID === null;
            const isSameStrategyAlreadySet =
                this.selectedStrategyID === this.currentUserStrategyID;

            return isStrategyNotSelected || isSameStrategyAlreadySet;
        },

        currentUserStrategyID() {
            const {
                queue: { strategy: { id = null } = {} } = {},
            } = this.userData;

            return id;
        },

        strategiesOptions() {
            return this.$store.getters['app-strategies/GET_STRATEGIES'] || [];
        },
    },

    created() {
        this.selectedStrategyID = this.currentUserStrategyID;
    },

    methods: {
        changeUserStrategy(event) {
            if (this.isConfirmButtonDisabled) {
                event.preventDefault();

                this.showNotification({
                    title: this.$t('strategy.chooseError'),
                    variant: 'danger',
                });

                return;
            }

            this.$bvModal.hide('modalChangeStrategy');

            this.showNotification({
                title: 'TODO - API call to change user strategy',
                variant: 'danger',
            });

            // TODO - API call to change user strategy
            // console.log('Change user strategy to id', this.selectedStrategyID);
        },

        setStartAtNow() {
            this.setQueueStartAtNow({
                userId: this.userData.id,
            }).then(() => {
                this.showNotification({
                    title: this.$t('ui.success'),
                    variant: 'success',
                });
            });
        },
    },

    setup(props) {
        const { showNotification } = usePositionedToast();
        const { setQueueStartAtNow } = useUserPayments({ props });

        return {
            // External methods
            formatDate,

            // Methods
            showNotification,
            setQueueStartAtNow,
        };
    },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
