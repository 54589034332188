import i18n from '@/libs/i18n/index';
import { ref, reactive, watch } from '@vue/composition-api';
import { formatDate, getFormattedBankCardDate } from '@/utils/formatter';

export default function useUserPayments() {
    const i18nReactive = reactive(i18n);

    const resolveCardVariants = () => {
        return {
            default: {
                color: 'success',
                background: 'bg-success',
                icon: 'CheckCircleIcon',
                text: {
                    block: i18nReactive.t('card.blockedLast'),
                    status: i18nReactive.t('card.default'),
                },
            },

            active: {
                color: 'success',
                background: 'bg-success',
                icon: 'CheckCircleIcon',
                text: {
                    block: i18nReactive.t('card.blockedLast'),
                    status: i18nReactive.t('card.active'),
                },
            },

            blocked: {
                color: 'warning',
                background: 'bg-warning',
                icon: 'ClockIcon',
                text: {
                    block: i18nReactive.t('card.blockedUntil'),
                    status: i18nReactive.t('card.blockedTemp'),
                },
            },

            deleted: {
                color: 'danger',
                background: 'bg-danger',
                icon: 'XCircleIcon',
                text: {
                    block: i18nReactive.t('card.deleted'),
                    status: i18nReactive.t('card.blocked'),
                },
            },
        };
    };

    const cardVariants = ref(resolveCardVariants());

    const getCardVariant = (card) => {
        if (!card.is_active && card.block === null)
            return cardVariants.value.deleted;
        if (card.block?.deleted_at) return cardVariants.value.deleted;
        if (card.block?.expired_at) return cardVariants.value.blocked;
        if (card.is_default) return cardVariants.value.default;

        return cardVariants.value.active;
    };

    const getBlockDate = (card) => {
        if (!card || !card.block) return '';

        return formatDate(
            card.block.deleted_at ||
                card.block.expired_at ||
                card.block.blocked_at,
            i18nReactive.locale,
            {
                month: 'long',
                day: 'numeric',
                year: 'numeric',
            }
        );
    };

    watch(
        () => i18nReactive.locale,
        () => {
            cardVariants.value = resolveCardVariants();
        }
    );

    return {
        // Methods
        getCardVariant,
        getBlockDate,

        // External methods
        formatDate,
        getFormattedBankCardDate,
    };
}
